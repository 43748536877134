<template>
	<edit-template class="role-edit" ref="edit">
		
    <!-- 大表单 -->
    <div class="form-body">
      <el-form ref="form" :model="form" :rules="rules" label-width="150rem">
        <el-form-item label="选择校区" prop="school_id">
          {{ form.school }}
        </el-form-item>

        <el-form-item label="任务周期" prop="task_cycle_id">
          {{ form.task_cycle }}
        </el-form-item>

        <div class="container">
          <el-row v-for="(item, index) in form.coach" style="margin-top: 20rem" class="">
            <el-col :span="20" class="container-align bg_rd">

              <el-form-item label="选择学科">
                <div v-if="form.coach[index].teacher_data ? (form.coach[index].teacher_data.length > 0 ? true : false) : false">
                  <el-select :popper-append-to-body="false" disabled="" v-if="!display" display="" @change="subjectChange(index)" v-model="form.coach[index].subject_id" placeholder="请选择学科">
                    <el-option v-for="(item, index) in disciplineArr" :key="item.id" :label="item.subject_name" :value="item.id"></el-option>
                  </el-select>
                </div>
                <div v-else="">
                  <el-select :popper-append-to-body="false" v-if="!display" @change="subjectChange(index)" v-model="form.coach[index].subject_id" placeholder="请选择学科">
                    <el-option v-for="(item, index) in disciplineArr" :key="item.id" :label="item.subject_name" :value="item.id"></el-option>
                  </el-select>
                </div>
                <span v-if="display">{{ form.coach[index].subject }}</span>
              </el-form-item>

              <el-form-item label="最低辅导次数">
                <el-input-number v-model="form.coach[index].number" :min="minNumber" :disabled="display" label="最低辅导次数"></el-input-number>
              </el-form-item>

              <el-form-item label="选择教师" style="display: block;">
                <el-button @click.prevent="dialogShow(item, index)" type="primary" :disabled="display">选择教师</el-button>
                <div v-if="form.coach[index].teacher_ids">
                  <el-tag v-for="item in form.coach[index].teacherObj" style="margin-right: 10rem">
                    {{ JSON.parse(item).clerk_name }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="2" style="color: #fff"></el-col>
            <el-col :span="3" style="padding-top: 20rem; display: flex; margin: auto; padding-left: 50rem" v-if="!display">
              <el-button @click.prevent="deleteform(index)" v-if="form.coach.length !== 1" :disabled="form.coach[index].is_delete == 0 ? true : false">删除
              </el-button>
              <el-button @click.prevent="addform" type="primary" v-if="form.coach.length == index + 1">添加</el-button>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <!-- 底部按钮 -->
    <template slot="footer">
      <el-button type="primary" @click="save('form')" style="margin-left: 50rem" v-if="!display">保存</el-button>
      <el-button class="lowerbtn" @click="open" v-if="!display">取消</el-button>
      <el-button class="lowerbtn" @click="() => this.$router.back()" v-if="display">返回</el-button>
    </template>
    <el-dialog title="选择老师" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-checkbox v-model="checked" border="" @change="allTeachers" v-if="teachersArr.length">全部教师</el-checkbox>
      <br>
      <br>
      <el-checkbox-group v-model="teacher_ids">
        <el-checkbox v-for="(item, index_) in teachersArr" :label="JSON.stringify(item)" border="" @change="teachersChange" :disabled="forbidden.some(item_ => { return  item_.teacher_id == item.id })">
          {{ item.clerk_name }}
        </el-checkbox>

      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="teachersSave">保存</el-button>
			</span>
    </el-dialog>
  
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
    return {
      disabled: [],
      form: {
        task_cycle_id: '',
        coach: [
          {
            subject_id: '',
            number: 1,
            teacher: [],
            teacherObj: [],
            teacher_ids: []
          }
        ]
      },
      minNumber: 1,
      index: null,
      teacher_ids: [],
      campusArr: [],
      taskcycleArr: [],
      disciplineArr: [],
      teachersArr: [],
      rules: {
        school_id: [{required: true, message: '请选择校区', trigger: 'change'}],
        task_cycle_id: [{required: true, message: '请选择任务周期', trigger: 'change'}]
      },
      dialogVisible: false,
      checked: false,
      display: null,
      forbidden: []
    }
  },

  methods: {
    save(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          for (let index = 0; index < this.form.coach.length; index++) {
            if (this.form.coach[index].subject_id == '') {
              return this.$message.error('请选择科目')
            }
            if (this.form.coach[index].teacher_ids.length == 0) {
              return this.$message.error('请选择教师')
            }
          }
          this.$_axios2.post('api/1v1/coach/' + this.$route.query.id, this.form).then(res => {
            if (res.data.status == 0) {
              this.$message({
                type: 'success',
                message: '修改成功!'
              })
              this.$router.back()
            }
            // this.$message.success('编辑成功')
            // this.$router.back()
          })
        }
      })
    },
    open() {
      this.$confirm('是否取消编辑', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.back()
      }).catch(() => {
      });
    },
    deleteform(index) {
      this.$confirm('是否删除此学科', {
        confirmButtonText: '删除',
        cancelButtonText: '点错了',
        type: 'warning'
      }).then(() => {
        if (!this.form.coach[index].id) {
          this.form.coach.splice(index, 1)
        } else {
          this.$_axios2.delete('api/1v1/coach/delete?id=' + this.form.coach[index].id).then(res => {
            this.form.coach.splice(index, 1)
          })
        }
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
      });
    },
    addform() {
      this.form.coach.push({
        subject_id: '',
        number: 1,
        teacher: [],
        teacherObj: [],
        teacher_ids: [],
        teacher_data: []
      })
    },
    async dialogShow(val, index) {
      this.disabled = []
      this.index = index
      this.dialogVisible = true
      await this.$_axios2
          .get('api/1v1/coach/get-teacher', {
            params: {
              school_id: this.form.school_id,
              subject_id: val.subject_id,
            }
          })
          .then(res => {
            this.teachersArr = res.data.data
            this.teacher_ids = this.form.coach[index].teacher
            this.teachersArr.forEach((i, n) => {
              this.form.coach[0].teacher_data.forEach((i_, n_) => {
                if (i.id === parseInt(i_.teacher_id)) {
                  if (i_.is_coach === 1) {
                    this.forbidden.push(i_)
                  }
                }
              })
            })
            let teacherIds = []
            this.form.coach[index].teacher_data.forEach((item, index_) => {
              if (item.is_coach != 0) {
                teacherIds.push(this.teacher_ids[index_])
              }
            })
            sessionStorage.setItem('teacher_ids', JSON.stringify(teacherIds))
          })
      this.checked = this.teachersArr.length === this.teacher_ids.length;
    },
    allTeachers(val) {
      if (val) {
        this.teacher_ids = this.teachersArr.map(item => JSON.stringify(item))
      } else {
        this.teacher_ids = JSON.parse(sessionStorage.getItem('teacher_ids'))
      }
    },
    teachersChange() {
      this.checked = this.teacher_ids.length === this.teachersArr.length;
    },
    subjectChange(index) {
      this.form.coach[index].teacherObj = []
      this.form.coach[index].teacher = []
    },
    teachersSave() {
      this.dialogVisible = false
      this.form.coach[this.index].teacherObj = this.teacher_ids
      this.form.coach[this.index].teacher = this.teacher_ids.map(item => item)
      // console.log(this.form.coach[this.index].teacher)
      this.form.coach[this.index].teacher_ids = []
      this.form.coach[this.index].teacherObj.forEach(item => {
        this.form.coach[this.index].teacher_ids.push(JSON.parse(item).id)
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.teacher_ids = sessionStorage.getItem('teacher_ids')
    },
    taskCycle(val) {
      this.taskcycleArr.forEach(item => {
        if (item.id == val) {
          this.minNumber = item.number
          this.form.coach.forEach(item => {
            item.number = this.minNumber
          })
        }
      })
    }
  },

  created() {
    this.$_axios.get('site/school').then(res => {
      this.campusArr = res.data.data
    })
    this.$_axios.get('site/fist-subject').then(res => {
      this.disciplineArr = res.data.data
    })
    this.$_axios2.get('api/1v1/coach/' + this.$route.query.id).then(res => {
      this.form = res.data.data
      this.minNumber = parseInt(this.form.min_number)
      this.$forceUpdate()
      this.form.school_id = this.form.school_id.toString()
      this.form.coach.forEach(item => {
        item.subject_id = item.subject_id.toString()
      })
      this.$_axios2.get('api/1v1/coach/get-task-cycle?school_id=' + this.form.school_id).then(res => {
        this.taskcycleArr = res.data.data
      })
    })
    if (this.$route.query.type == 0) {
      this.display = true
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20rem;
  padding: 20rem;
}

.container-align {
  padding-top: 20rem;
  display: flex;
  flex-wrap: wrap;

  .form_item {
    width: 12vw !important;
  }
}

@media (max-width: 1322rem) {
  .container {
    width: 1000rem !important;
  }
}

.el-checkbox-group {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: left !important;
  margin-left: 0rem !important;
}

.el-checkbox {
  margin-left: 0rem !important;
}

::v-deep .el-checkbox {
  min-width: 50rem !important;
  margin-bottom: 10rem !important;
}
</style>
